import { NextPage, NextPageContext } from 'next';

import { logger } from '@common/logger';

interface Props {
    statusCode?: number;
}

const Error: NextPage<Props> = ({ statusCode }) => {
    return <p>{statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}</p>;
};

Error.getInitialProps = ({ res, err }: NextPageContext) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    if (err) logger.error(err);
    return { statusCode };
};

export default Error;
